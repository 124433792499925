.unit-information-box-v1 {
	padding: 20px 15px;
	background-color: var(--widget-header-bg-color);
	font-size: var(--font-size-md);

	.map {

		.sidebar-map {
			width: 100%;
			height: 300px;
			position: relative;
		}
	}

	.location {

		.fa {
			margin-right: 8px;
		}
	}

	.facts {

		li {
			margin-bottom: 5px;
			display: flex;
			align-items: center;

			.fa {
				width: 30px;
			}

			.fa-stack {
				margin-left: -10px;
			}

			&:nth-child(1) {
				.fa {
					font-size: 12px;
				}
			}

			&:nth-child(2) {
				.fa {
					font-size: 18px;
				}
			}


		}
	}

	.stars {
		color: var(--color-stars);
	}

}