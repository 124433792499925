.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}
	}

	.expandButton {
		position: absolute;
		right: 10px;
		top: 10px;
		border-radius: var(--btn-border-radius);
		background-color: var(--btn-default-bg-color);
		border: 1px solid var(--btn-default-border-color);
		font-size: var(--font-size-sm);


		&:before {
			content: "\f068";
			position: relative;
			top: 2px;
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
		}

		&.show {
			&:before {
				content: "\f067";
			}
		}
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

	.matterport-view {
		iframe {
			width: 100%;
			height: 565px;

			@media(max-width: 1499px) {
				height: 480px;
			}

			@media(max-width: 1199px) {
				height: 530px;
			}

			@media(max-width: 991px) {
				height: 400px;
			}

			@media(max-width: 767px) {
				height: 300px;
			}

			@media(max-width: 480px) {
				height: 260px;
			}

		}
	}

}