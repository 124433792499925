.newsletter-bl {
	background-color: var(--color-grey-dark);
	color: var(--color-white);

	.form-control {
		background-color: var(--color-grey-normal);
		color: var(--color-white);
		border-color: var(--color-grey-normal);
		font-size: 1.375rem;
		height: 3.125rem;
		border-radius: 0;
		padding: 0.5rem 1.25rem;

		&::-webkit-input-placeholder {
			/* Edge */
			color: var(--color-white);
		}

		&:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: var(--color-white);
		}

		&::placeholder {
			color: var(--color-white);
		}
	}

	.btn {
		letter-spacing: .1875rem;
		line-height: 1rem;
		padding: 0.875rem 1.875rem;
		font-family: var(--font-roboto);
		margin-top: 1.875rem;
		border-radius: 0;
		font-size: 0.875rem;
		text-transform: uppercase;
	}
}

.footer-v1 {
	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-grey-dark);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}


		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			@media (max-width:992px) {
				text-align: left;
			}

			.fa {
				color: var(--color-grey-dark);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;
			font-size: 18px;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			padding-right: 20px;

			img {
				width: 50px;
			}
		}
	}

}

.v-office-logo {
	text-align: right;
	margin-top: 15px;

	@media (max-width:992px) {
		text-align: left;
	}
}