.showLess {
	position: relative;

	&.showFull {
		.unit-offers-v1 {
			.offer-bl {
				display: block !important;
			}
		}

		.hide-show-btn {

			&:after {
				content: "\f106";
			}
		}
	}

	.unit-offers-v1 {

		.offer-bl {
			display: none;

			&:nth-child(-n+2) {
				display: block;
			}
		}
	}



	.hide-show-btn {
		position: absolute;
		bottom: -14px;
		left: 50%;
		transform: translate(-50%, 0);
		padding: 5px 15px;
		background-color: var(--white);
		display: inline-block;
		border: 1px solid var(--btn-border-color-hide-show);
		font-size: var(--font-size-md);

		&:focus,
		&:hover {
			text-decoration: none;
		}

		&:after {
			content: "\f107";
			margin-left: 10px;
			margin-left: 5px;
			font-family: fontawesome;
		}
	}
}

.unit-offers-v1 {
	.unit-offer {
		padding: 30px;
		background-color: var(--color-secondary-light);
		position: relative;
		margin-bottom: 30px;

		.offer-minstay {
			font-size: var(--font-size-sm);

		}

		.offer-minstay-xy {
			font-size: var(--font-size-md);

		}

		.offer-condition {
			font-size: var(--font-size-md);
		}

		.offer-banner {
			position: absolute;
			background-color: var(--color-red);
			color: var(--color-white);
			top: -13px;
			left: -8px;
			padding: 2px 10px;
			text-transform: uppercase;
			font-size: var(--font-size-sm);
			border-radius: 5px;
			box-shadow: 1px 0 3px 0 rgb(0 0 0 / 35%);

		}

		.offer-detail {
			display: none;
		}


	}
}