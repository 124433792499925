.navbar-v1.affix,
.navbar-v1 {
	.header-logo {
		position: absolute;
		top: 0px;
		z-index: 20;
		padding: 5px 0;
		left: 10px;

		&.v2 {
			@media screen and (max-width: 1200px) {
				display: none;
			}

			right: 10px;
			left: inherit;
		}

		.logo {
			width: 100px;
			height: auto;

		}

	}

	position: fixed;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;
	padding: 0;
	z-index: 50;
	box-shadow: 0 3px 3px -3px grey;
	background-color: var(--nav-bg-color);

	@media (max-width:1200px) {
		position: absolute;
	}

	.input-search-unit {
		padding: 5px 15px;
		max-width: 280px;

		@media (max-width:1200px) {
			max-width: 100%;

		}

		@media (max-width:1200px) {
			margin-bottom: 5px;
		}

		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #aaa;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
			padding: 0px 15px;
		}
	}

	/*.top-nav {
		padding: 3px 0 8px;
		text-align: right;
		font-size: var(--top-nav-font-size);
		font-family: var(--bodyfont);
		background-color: var(--top-nav-bg-color);

		ul {
			padding: 0;
			margin: 0;
			display: inline-flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-end;

			li {
				display: inline-block;
				position: relative;
				font-size: var(--top-nav-font-size);
				color: var(--top-nav-font-color);

				@media (max-width:1200px) {
					display: block;
				}

				a {
					color: var(--top-nav-font-color);
					font-size: var(--top-nav-font-size);
					text-decoration: none;
					padding: 5px 15px;

					@media (max-width:550px) {
						padding: 5px 4px;
					}

					&:hover {
						color: var(--top-nav-font-color-hover)
					}

					i.fa {
						font-size: var(--top-nav-icon-size);
						margin-right: 5px;
					}
				}
			}
		}



}*/

	.header-main {

		.col-12 {
			justify-content: center;
			display: flex;

			@media screen and (max-width: 1200px) {
				justify-content: flex-end;
				position: static;
			}
		}

		.main-menu,
		.social-li {
			float: right;


			@media (max-width:1200px) {
				position: absolute;
				left: 0;
				top: 100%;
				margin-right: 0;
			}

			.fav-count {
				background: var(--color-primary);

				a {
					color: var(--color-white);
				}

				&:hover {
					background: var(--color-secondary);
				}

				.sub-count {
					-moz-border-radius: 50%;
					-webkit-border-radius: 50%;
					border-radius: 50%;
					display: block;
					position: absolute;
					top: -5px;
					left: 2px;
					width: 18px;
					height: 18px;


					text-align: center;
					font-size: 12px;
					line-height: 18px;
				}
			}

			>ul {
				padding: 0;
				margin: 0;
				border-left: 1px solid #ddd;

				>li {
					float: left;
					display: inline-block;
					border-right: 1px solid #ddd;
					display: block;

					@media (max-width:1200px) {
						display: block;
						width: 100%;
					}

					&:hover {
						background-color: var(--color-grey-light);

						a {
							text-decoration: none;
						}
					}


					>a {
						color: var(--main-nav-font-color);
						text-transform: var(--main-nav-text-transform);
						font-size: var(--main-nav-font-size);
						position: relative;
						line-height: 1;
						text-transform: uppercase;
						font-weight: 400;
						font-family: var(--font-roboto);
						color: var(--color-grey-normal);
						padding: 25px 20px;
						display: block;

						&.text-blue {
							color: var(--color-secondary) !important;
						}
					}

					&.show {
						background-color: var(--color-grey-light);
					}

					&.dropdown {

						cursor: pointer;

						.dropdown-backdrop {
							display: none;
						}


					}




				}
			}

			.btn {
				@media (max-width:340px) {
					font-size: 12px;
					padding: 8px 8px;
				}
			}
		}
	}


	.dropdown-menu {
		border-radius: 0;
		border: none;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		min-width: 300px;
		margin-top: -1px;
		padding: 0;

	}

	.sub-nav {

		ul {
			list-style-type: none;

			li {
				border-top: 1px solid #ddd;

				a {
					color: var(--main-nav-font-color);
					text-transform: var(--main-nav-text-transform);
					font-size: var(--main-nav-font-size);
					position: relative;
					line-height: 1;
					text-transform: uppercase;
					font-weight: 400;
					font-family: var(--font-roboto);
					color: var(--color-grey-normal);
					padding: 20px;
					display: block;
				}

				&:hover {
					background-color: var(--color-grey-light);

					a {
						text-decoration: none;
					}
				}

			}
		}


	}

	.icon {
		display: none;
		right: 0;
		text-decoration: none;
		color: var(--color-secondary);
		font-size: 30px;
		float: right;
	}

	.navactive {
		@media (min-width:1201px) {
			color: var(--main-nav-font-color-hover) !important;
			background-color: var(--main-nav-bg-hover) !important;
		}

	}

	@media (min-width:1201px) {

		.main-menu,
		.social-li {
			display: flex;
		}
	}


	@media screen and (max-width: 1200px) {

		.header-logo {
			padding-left: 0px !important;
		}

		.social-li {
			position: static !important;

			ul {
				li {
					float: left !important;
					width: auto !important;

					a {
						padding: 20px 14px !important;
					}

					span {
						display: none;
					}
				}
			}
		}

		.nav-row {
			min-height: 60px !important;
		}

		.icon {
			display: block;
			bottom: -7px;
			position: relative;
		}

		.dropdown-menu {
			position: relative !important;
			transform: none !important;
			width: 100%;
			border-top: none;
			padding: 0;
		}

		.main-menu {
			background-color: var(--nav-bg-color);
			padding: 0;
			z-index: 50;
			display: none;
			width: 100%;
			border-bottom: 1px solid #61847a;

			ul {
				li {
					border-top: 1px solid #ddd;
				}
			}
		}

		.search-unit-ul {
			margin: 15px 0px 0px;

			.input-search-unit {
				.input-group {
					.form-control {
						@media (max-width: 574px) {
							height: 40px !important;
						}
					}
				}
			}
		}
	}


	@media screen and (min-width: 1201px) {
		.main-menu {
			display: block !important;
		}
	}

	&.affix-top {


		.header-main {

			.header-logo {

				.logo {}

			}

			.main-menu {

				>ul {
					padding: 0;
					margin: 0;

					>li {


						&.dropdown {

							cursor: pointer;

							.dropdown-backdrop {
								display: none;
							}


						}




					}
				}

				.btn {
					@media (max-width:340px) {
						font-size: 12px;
						padding: 8px 8px;
					}
				}
			}
		}
	}

}

.hide-m {
	@media (max-width:767px) {
		display: none !important;
	}
}

.hide-d {
	display: none !important;

	@media (max-width:767px) {
		display: block !important;
	}
}

.mobil-menu {
	position: fixed;
	cursor: pointer;
	display: none;
	bottom: 20px;
	right: 5px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
	z-index: 200;
	line-height: 40px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

	@media (min-width:1101px) {
		display: none !important;
	}
}

.open-by-mob {
	#mob-nav-icon {
		display: none;
	}

}