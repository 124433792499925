.static-pages {
	margin-top: var(--page-margin-top);

	&.ferienanlagen-v1 {
		.boxes {
			img {
				width: 100%;
			}

			.img-col {
				position: relative;

				.unit-cont-bl {
					a {
						background-color: rgb(0 157 219/70%);
						position: absolute;
						width: 100%;
						border: 0;
						padding: 10px 0;
						bottom: 0;
						text-align: center;
						color: #fff;
						text-decoration: none;
					}
				}
			}
		}
	}
}