.index-slide-show-v1 {
	position: relative;
	height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);

	@media (max-height: 600px) {
		min-height: 680px;

	}

	.owl-carousel {
		z-index: unset;
	}


	.index-slide {
		height: var(--index-slide-show-height);
		margin-top: var(--index-slide-show-margin-top);

		.item {
			height: var(--index-slide-show-height);
			overflow: hidden;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.slide-item {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			position: relative;
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
		}

		.picture {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			position: relative;

			@media (max-height: 600px) {
				min-height: 680px;

			}

			.img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	.carousel-item {
		height: var(--index-slide-show-height);
		overflow: hidden;

		@media (max-height: 600px) {
			min-height: 680px;

		}
	}

	.carousel-item img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.slide1 {
		background-image: url(../img/slide1-1980.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.hero-header {
		display: none;
		position: absolute;
		top: 120px;
		font-family: 'Poppins', cursive;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		@media (max-height: 600px) {
			top: 120px;

		}

		.hero-text {
			text-align: center;
			width: 100%;

			.hero-headline {
				width: 100%;
			}

			h1 {
				font-family: var(--hero-header-font-family);
				font-size: 3.8vw;
				color: #fff;
				text-shadow: 0 2px 2px rgb(0 0 0 / 70%);
				font-weight: 400;
				margin-bottom: 0px;


				@media (min-width: 1600px) {
					font-size: 66px;
				}

				@media (max-width: 1140px) {
					font-size: 48px;
				}

				@media (max-width: 992px) {
					font-size: 2.8rem;
					line-height: 3rem;
				}

				@media (max-width: 576px) {
					font-size: 2.5rem;
					line-height: 2.8rem;
				}

				@media (max-height: 600px) {
					font-size: 2.5rem;
					line-height: 2.5rem;

				}

				big {

					display: block;
					padding: 5px;

					&#text1 {
						color: var(--color-secondary);
						font-size: 4.8vw;
						line-height: 1.3;

						@media (min-width: 1600px) {
							font-size: 94px;
						}

						@media (max-width: 1140px) {
							font-size: 58px;
						}

						@media (max-width: 992px) {
							font-size: 3.2rem;
							line-height: 3.2rem;
						}

						@media (max-width: 576px) {
							font-size: 3rem;
							line-height: 3rem;
						}

						@media (max-height: 600px) {
							font-size: 3rem;
							line-height: 3rem;

						}
					}
				}


				.subheading {
					-webkit-animation: 3s ease 0s normal forwards 1 fadein;
					animation: 3s ease 0s normal forwards 1 fadein;
				}

				@keyframes fadein {
					0% {
						opacity: 0;
					}

					66% {
						opacity: 0;
					}

					100% {
						opacity: 1;
					}
				}

				@-webkit-keyframes fadein {
					0% {
						opacity: 0;
					}

					66% {
						opacity: 0;
					}

					100% {
						opacity: 1;
					}
				}

			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 2.5rem;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);

				@media (min-width: 1600px) {
					font-size: 2.5rem;
				}

				@media (max-width: 1140px) {
					font-size: 2.0rem;
				}

				@media (max-width: 992px) {
					font-size: 2.0rem;
				}

				@media (max-width: 575px) {
					font-size: 1.5rem;
				}
			}
		}

	}
}