.unit-description-v2 {

	.title {
		font-size: 22px;
		font-weight: 600;
		margin-bottom: 0rem;
	}

	.description {
		margin-bottom: 40px;
	}

	.showLessText {
		position: relative;

		&.showFull {
			.description-text {
				display: block;
			}


			.hide-show-btn {

				&:after {
					content: "\f106";
				}
			}
		}

		.description-text {
			display: none;
		}

		.first {
			display: block;
		}

		.hide-show-btn {
			position: absolute;
			bottom: -50px;
			left: 50%;
			transform: translate(-50%, 0);
			padding: 5px 15px;
			background-color: var(--white);
			display: inline-block;
			border: 1px solid var(--btn-border-color-hide-show);
			font-size: var(--font-size-md);


			&:focus,
			&:hover {
				text-decoration: none;
			}

			&:after {
				content: "\f107";
				margin-left: 10px;
				margin-left: 5px;
				font-family: fontawesome;
			}
		}
	}

}